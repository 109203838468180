const FileSaver = require('file-saver');
const XLSX = require('sheetjs-style');

module.exports = {
    exportCSVFile(headers, items, fileTitle) {
        if (headers) {
            items.unshift(headers);
        }
    
        // Convert Object to JSON
        var jsonObject = JSON.stringify(items);
    
        var csv = convertToCSV(jsonObject);
    
        var exportedFilenmae = fileTitle + '.xlsx' || 'export.xlsx';
    
        var blob = new Blob([csv], { type: 'text/xlsx;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    },
    exportXLSXFile(json, fileTitle, fields) {
        const list = [...json].map(item => {
            if (fields) {
                const obj = {};
                fields.forEach(field => {
                    obj[field] = item[field];
                });
                return obj;
            } else {
                return item;
            }
        });

        const data = list;
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(data);
        const all_width = []
        for (var key in fields) {
            all_width.push({ wpx: 150 })
        }
        ws['!cols'] = all_width;
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data1 = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data1, fileTitle + fileExtension);
    },
    xlxsBase64toJson(base64) {
        const cleanBase64 = base64.replace(/\s/g, '');
        const workbook = XLSX.read(cleanBase64, { type: 'base64' });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const json = XLSX.utils.sheet_to_json(sheet);
        json.shift();

        return json;
    },
}

function convertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line != '') line += ','

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
}